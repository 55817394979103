import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import Blog from '../components/BlogSingle/BlogSingle';
import Footer from '../components/Footer/Footer2';

class BlogSingle extends Component {
    render() {
        return (
            <div className="main">
                <Blog />
                <Footer />
            </div>
        );
    }
}

export default withRouter(BlogSingle) ;