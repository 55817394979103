import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { getCurrentUser, logout } from "../actions"
// importing all the themes
import ThemeOne from "../themes/theme-one";
import ExploreOne from "../themes/explore-one";
import ExploreTwo from "../themes/explore-two";
import ExploreThree from "../themes/explore-three";
import ExploreFour from "../themes/explore-four";
import Auctions from "../themes/auctions";
import ItemDetails from "../themes/item-details";
import Blog from "../themes/blog";
import BlogSingle from "../themes/blog-single";
import HelpCenter from "../themes/help-center";
import Authors from "../themes/authors";
import Author from "../themes/author";
import WalletConnect from "../themes/wallet-connect";
import Create from "../themes/create";
import Login from "../themes/login";
import Signup from "../themes/signup";
import Redeem from "../themes/redeem";
import History from "../themes/history";
import TranferPoint from "../themes/tranferpoint";
import Transaction from "../themes/transaction";
import Header from "../components/Header/Header";
import HeaderUser from "../components/Header/HeaderUser";
import history from "../history/history";
import changepassword from "../themes/changepassword";
import ModalMenu from "../components/Modal/ModalMenu";
import security from "../themes/security";



const RestrictedRoute = ({ component: Component, user, ...rest }) => (

  <Route
    {...rest}
    render={props =>
      user ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: props.location }
          }}
        />
      )
    }
  />
);


class MyRouts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isReady: false,
      user: null,
      token: ''
    }
  }
  setAuthenticated = async () => {
    await logout()
    localStorage.removeItem('token');
    this.setState({ user: null });
    window.location.href = '/';
  }
  componentDidMount = async () => {
    const user = await getCurrentUser();
    try {
      if (user) {
        await user.fetch({ useMasterKey: true })
        const token = localStorage.getItem('token')
        console.log('user', user);
        this.setState({ user: user.toJSON(), token: token }, () => {
          this.setState({ isReady: true })
        });
      } else {
        this.setState({ user: null }, () => {
          this.setState({ isReady: true })
        });
      }
    } catch (error) {
      console.log('error', error.message);
      this.setState({ user: null }, () => {
        this.setState({ isReady: true })
      });
    }

  }

  render() {
    const { isReady, user } = this.state;
    return (
      <div>
        {
          isReady ? (
            <>
              {user ? (
                <HeaderUser user={user} setAuthenticated={() => this.setAuthenticated()} />
              ) : (
                <Header setAuthenticated={() => this.setAuthenticated()} />
              )}
              <BrowserRouter history={history}>
                <Switch>
                  <Route exact path="/" component={ThemeOne} />
                  <Route exact path="/rule" component={BlogSingle} />
                  <Route exact path="/login" component={Login} />
                  <Route exact path="/signup" component={Signup} />
                  <RestrictedRoute path="/history" component={History} user={user} />
                  <RestrictedRoute path="/redeem" component={Redeem} user={user} />
                  <RestrictedRoute path="/tranferpoint" component={TranferPoint} user={user} />
                  <RestrictedRoute path="/transaction" component={Transaction} user={user} />
                  <RestrictedRoute exact path="/changepassword" component={changepassword} user={user} />
                  <RestrictedRoute exact path="/security" component={security} user={user} />

                  <Route exact path="/explore-1" component={ExploreOne} />
                  <Route exact path="/explore-2" component={ExploreTwo} />
                  <Route exact path="/explore-3" component={ExploreThree} />
                  <Route exact path="/explore-4" component={ExploreFour} />
                  <Route exact path="/auctions" component={Auctions} />
                  <Route exact path="/item-details" component={ItemDetails} />
                  <Route exact path="/blog" component={Blog} />
                  <Route exact path="/blog-single" component={BlogSingle} />
                  <Route exact path="/help-center" component={HelpCenter} />
                  <Route exact path="/authors" component={Authors} />
                  <Route exact path="/author" component={Author} />
                  <Route exact path="/wallet-connect" component={WalletConnect} />
                  <Route exact path="/create" component={Create} />
                </Switch>
              </BrowserRouter>
            </>

          ) : (<div class="text-center">
            <div class="spinner-border text-primary" style={{marginTop:80}} role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>)
        }
        <ModalMenu user={user} />
      </div>
    );
  }
}
export default MyRouts;