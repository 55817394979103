import React, { useState, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
//import logo from '../../image/poker-reward-logo-left.png'
import logo from '../../image/logo-header.png'
import { getCurrentUser } from '../../actions'

const Header = ({ setAuthenticated }) => {
    return (
        <header id="header">
            {/* Navbar */}
            <nav data-aos="zoom-out" data-aos-delay={400} className="navbar navbar-expand">
                <div className="container header">
                    {/* Navbar Brand*/}
                    <a className="navbar-brand" href="/">
                        <img className="navbar-brand-sticky" src={logo} alt="sticky brand-logo" />
                    </a>
                    <div className="ml-auto" />
                    {/* Navbar */}
                    <ul className="navbar-nav items" >
                        <li className="nav-item"><a href="/" className="nav-link" >Home</a></li>
                        <li className="nav-item"><a href="/rule" className="nav-link">กติกา</a></li>
                        <li className="nav-item">
                            <a href="/login" className="nav-link">Log In</a>
                        </li>
                        <li className="nav-item">
                            <a href="/signup" className="nav-link">ลงทะเบียน</a>
                        </li>
                    </ul>
                    <ul className="navbar-nav toggle">
                        <li className="nav-item">
                            <a href="#" className="nav-link" data-toggle="modal" data-target="#menu">
                                <i className="fas fa-bars toggle-icon m-0" />
                            </a>
                        </li>
                    </ul>
                </div>
            </nav>
        </header>
    );
};

export default Header;