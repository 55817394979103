import React from 'react';

const ModalMenu = ({ user }) => {
    return (
        <div id="menu" className="modal fade p-0">
            <div className="modal-dialog dialog-animated">
                <div className="modal-content h-100">
                    <div className="modal-header" data-dismiss="modal">
                        Menu <i className="far fa-times-circle icon-close" />
                    </div>
                    <div className="menu modal-body pt-0">
                        <div className="row w-100">
                            <div className="items p-0 col-12 text-center" >
                                <ul className="navbar-nav items" >
                                    <li className="nav-item"><a href="/" className="nav-link" >Home</a></li>
                                    <li className="nav-item"><a href="/rule" className="nav-link">กติกา</a></li>
                                    {user ? (<>
                                        <li className="nav-item">
                                            <a href="/history" className="nav-link">ประวัติการแลก</a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="/transaction" className="nav-link">ประวัติแต้ม</a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="/tranferpoint" className="nav-link">โอนคะแนน</a>
                                        </li>
                                    </>) : (<>
                                        <li className="nav-item">
                                            <a href="/login" className="nav-link">Log In</a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="/signup" className="nav-link">ลงทะเบียน</a>
                                        </li>
                                    </>)}

                                </ul>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalMenu;