import axios from "axios";
import Parse from "parse";
const URL = process.env.REACT_APP_API_URL
const PARSE_APP_ID = process.env.REACT_APP_PARSE_APP_ID
const PARSE_JAVASCRIPT_KEY = process.env.REACT_APP_PARSE_JS_KEY
const PARSE_MASTER_KEY = process.env.REACT_APP_PARSE_MASTER_KEY
const PARSE_SERVER_URL = process.env.REACT_APP_PARSE_URL


const init = () => {
    Parse.initialize(PARSE_APP_ID, PARSE_JAVASCRIPT_KEY, PARSE_MASTER_KEY);
    Parse.masterKey = PARSE_MASTER_KEY
    Parse.serverURL = PARSE_SERVER_URL
};

export const login = async (info) => {
    init()
    const { username, password } = info
    try {
        if (!username || !password) {
            return { status: 'error', message: 'Please fill in the username and password' };
        }
        const check = await checkRewardRegistration(username)
        if (check.status === 'error') {
            return { status: 'error', message: check.message };
        }
        const user = await Parse.User.logIn(username, password, { useMasterKey: true });
        if (user) {
            //await user.fetch({ useMasterKey: true });
            console.log('user', user.getSessionToken());
            const isUse2FA = user.get('isUse2FA')
            return { status: 'success', message: 'Log in success', user, token: user.getSessionToken(), isUse2FA };
        } else {
            const res = await axios.post(`${URL}/user/reward/wrongPassword`, { username })
            return res.data;
        }
    } catch (error) {
        console.log('login error ', error.message);
        const res = await axios.post(`${URL}/user/reward/wrongPassword`, { username })
        return res.data;
        //return { status: 'error', message: error.message }
    }
}

export const checkRewardRegistration = async (phone) => {
    try {
        const res = await axios.post(`${URL}/user/reward/check`, { phone })
        return res.data;
    } catch (error) {
        return { status: 'error', message: error.message }
    }
}

export const getCurrentUser = async () => {
    init()
    const token = window.localStorage.getItem('token')
    console.log('token', token);
    try {
        if (token) {
            const user = await Parse.User.become(token)
            console.log('user from token', user);
            return user
        } else {
            // const user = Parse.User.current();
            // console.log('user', user);
            // return user
            return null
        }
    } catch (error) {
        console.log('getCurrentUser error :>> ', error.message);
        return null
    }

}

export const logout = async () => {
    init()
    await Parse.User.logOut({ useMasterKey: true });
}

export const getRewardItem = async () => {
    try {
        const res = await axios(`${URL}/reward/item`)
        const { data } = res.data
        return { status: 'success', data };
    } catch (error) {
        return { status: 'error', message: error.message }
    }

}

export const fetchGet = () => (
    axios.get(process.env.REACT_APP_API_URL + "/")
)

export const fetchPost = values => (
    axios.post(process.env.REACT_APP_API_URL + "/login/" + JSON.stringify(values), {
        headers: {
            "content-type": "application/json"
        }
    })
)

export const fetchPush = (id, values) => {
    return dispatch => {
        // axios.put(process.env.REACT_APP_API_URL + "/lists/" + id, values).then(
        //     res => {
        //         dispatch({ type: UPDATE_LIST });
        //     }
        // )
    }
}

export const fetchDelete = values => {
    return dispatch => {
        // axios.delete(process.env.REACT_APP_API_URL + "/lists/" + values).then(res => {
        //     axios.get(process.env.REACT_APP_API_URL + "/").then(
        //         res => {
        //             dispatch({ type: LOAD_LIST, payload: res.data });
        //         }
        //     )
        // })
    }
}
