import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Footer from '../components/Footer/Footer2';
import SignupSection from '../components/Signup/Signup';

class Signup extends Component {
    render() {
        return (
            <div className="main">
                <SignupSection />
                <div style={{ marginTop: 100 }}></div>
                <Footer />
            </div>
        );
    }
}

export default withRouter(Signup);