import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import TranferSection from '../components/TranferPoint/Tranferpoint';
import Footer from '../components/Footer/Footer2';

class TranferPoint extends Component {
    render() {
        return (
            <div className="main">
                <TranferSection />
                <div style={{marginTop:200}}/>
                <Footer/>
            </div>
        );
    }
}

export default withRouter(TranferPoint);