import React, { Component } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2'
const API_URL = process.env.REACT_APP_API_URL;
const initData = {
    pre_heading: "Signup",
    heading: "Create Reward Account",
    content: "*การตั้งรหัสผ่านต้องมีมากกว่า 8 ตัวอักษรแต่ไม่เกิน 15 ตัวอักษร และไม่มีช่องว่าง"
}


class Signup extends Component {
    state = {
        phone: '',
        password: "",
        confirmPassword: "",
        currentUser: null,
        loading: false
    }
    submit = async () => {
        this.setState({ loading: true })
        const { phone, password, confirmPassword } = this.state;
        if (phone === '') {
            Swal.fire({
                title: 'Error!',
                text: 'กรุณากรอกเบอร์โทร',
                icon: 'error',
                confirmButtonText: 'Ok'
            })
            this.setState({ loading: false })
        } else if (password === confirmPassword) {
            if (passwordValidation(password)) {
                const data = { phone, password }
                const res = await axios.post(`${API_URL}/user/reward/signup`, data);
                if (res.data.status === 'success') {
                    Swal.fire({ title: res.data.message, text: 'กรุณา Log in ใหม่', icon: res.data.status }).then(async res => {
                        this.setState({ password: '', confirmPassword: '', phone: '' });
                        window.location.href = '/';
                    });

                } else {
                    Swal.fire({ title: res.data.message, icon: res.data.status });
                    this.setState({ loading: false })
                }
            } else {
                Swal.fire({
                    title: 'Error!',
                    text: 'กรุณากำหนด Password ให้ตรงตามเงื่อนไข',
                    icon: 'error',
                    confirmButtonText: 'Ok'
                })
                this.setState({ loading: false })
            }
        } else {
            Swal.fire({
                title: 'Error!',
                text: 'Password ไม่ตรงกัน',
                icon: 'error',
                confirmButtonText: 'Ok'
            })
            this.setState({ loading: false })
        }
    }
    render() {
        return (
            <section className="author-area pt-5 mt-5">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-8 col-lg-7">
                            {/* Intro */}
                            <div className="intro text-center" style={{ marginBottom: 20 }}>
                                <span>{initData.pre_heading}</span>
                                <h3 className="mt-3 mb-0">{initData.heading}</h3>
                                <p style={{marginLeft:20,marginRight:20}}>{initData.content}</p>
                            </div>
                            {/* Item Form */}
                            <div className="item-form card no-hover">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group mt-3">
                                            <input type="text" className="form-control" name="name" placeholder="กรุณาใส่หมายเลขโทรศัพท์" required="required" onChange={(e) => {
                                                this.setState({ phone: e.target.value })
                                            }} />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group mt-3">
                                            <input type="password" className="form-control" name="password" placeholder="กรุณาใส่ Password" required="required" onChange={(e) => {
                                                this.setState({ password: e.target.value })
                                            }} />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group mt-3">
                                            <input type="password" className="form-control" name="password" placeholder="ยืนยัน Password" required="required" onChange={(e) => {
                                                this.setState({ confirmPassword: e.target.value })
                                            }} />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <button className="btn w-100 mt-3 mt-sm-4" disabled={this.state.loading} onClick={() => { this.submit() }}>สร้าง Account</button>
                                    </div>
                                    <div className="col-12">
                                        <span className="d-block text-center mt-4">Already have an account? <a href="/login">Log In</a></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Signup;

const passwordValidation = (value) => {
    //var reg = new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{8,})");
    if (value.indexOf(' ') > -1) {
        return false
    }
    //var reg = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.{8,15}$)");
    var reg = new RegExp("^(?=.{8,}$)");
    if (!reg.test(value)) {
        return false
    } else {
        return true
    }
}