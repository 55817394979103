import React, { Component } from 'react';

const initData = {
    pre_heading: "Login",
    heading: "Login to your Account",
    content: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati dignissimos quae quo ad iste ipsum officiis deleniti asperiores sit."
}

class Login extends Component {
    state = {
        initData: {},
        data: []
    }
    componentDidMount() {
        this.setState({
            initData: initData
        })
    }

    render() {
        const { handleInputChange, state, handleSubmit } = this.props;
        return (
            <section className="author-area pt-5 mt-5">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-8 col-lg-7">
                            {/* Intro */}
                            <div className="intro text-center">
                                <span>{this.state.initData.pre_heading}</span>
                                <h4 className="mt-3 mb-0">{this.state.initData.heading}</h4>
                                {/* <p>{this.state.initData.content}</p> */}
                            </div>
                            {/* Item Form */}
                            <div className="item-form card no-hover" >
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group mt-3">
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="username"
                                                value={state.username}
                                                onChange={handleInputChange}
                                                placeholder="กรุณาใส่เบอร์โทรศัพท์"
                                                required="required" />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group mt-3">
                                            <input
                                                name="password"
                                                type="password"
                                                placeholder="กรุณาใส่รหัสผ่าน"
                                                value={state.password}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                    </div>
                                    
                                    {/* <div className="col-12">
                                        <div className="form-group mt-3">
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" defaultValue="option1" defaultChecked />
                                                <label className="form-check-label" htmlFor="inlineRadio1">Remember Me</label>
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className="col-12">
                                        <button
                                            className="btn w-100 mt-3 mt-sm-4"
                                            onClick={handleSubmit}>
                                            Sign In
                                        </button>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group mt-3">
                                            <div style={{color:'#ff2000'}}>{'** หากใส่รหัสผ่านผิด 3 ครั้ง ระบบจะล็อค User ทันที'}</div>
                                            <div style={{color:'#ff2000'}}>{'** ทางเราจะไม่รับผิดชอบการซื้อขายคะแนน และการโอนแต้มทั้งหมดที่เกิดจากผู้เล่น'}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Login;