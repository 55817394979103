import React, { Component } from 'react';

const initData = {
    textTitle: 'กฏกติกา การแลกของรางวัล'
}

const dataRule = [
    { header: 'วิธีการแลกของรางวัล', detail: ['เข้าสู่ระบบ', 'กดปุ่มแลกรางวัล', 'รอทีมงานติดต่อกลับ'] },
    { header: 'วิธีทำให้ได้คะแนน', detail: ['เข้าเล่น App PPPoker ในคลับ PokerTH','เข้าเล่น App Xpoker ในคลับ XpokerTH','เข้าเล่น App PokerBros ในคลับ PokerTH', 'คะแนนจะอัปเดททุกๆ 1 สัปดาห์ ในวันจันทร์', 'คะแนนจะคำนวณจากการเล่นของแต่ละ ID ยิ่งเล่นเยอะ ยิ่งได้เยอะ'] },
    { header: 'หมายเหตุ', detail: ['เมื่อกดแลกของรางวัลแล้ว ไม่สามารถยกเลิกหรือเปลี่ยนของรางวัลได้', 'คะแนนจะเกิดขึ้นจากการเล่นภายในคลับ PokerTH เท่านั้น', 'คะแนนจะถูกคำนวณโดยระบบโปรแกรมคอมพิวเตอร์','การตัดสินใจของทีมงานถือเป็นที่สิ้นสุด'] },
]

class BlogSingle extends Component {
    state = {
        initData: '',
        data: []
    }
    componentDidMount() {
        this.setState({
            initData: initData,
            data: dataRule
        })
    }
    render() {
        return (
            <section className="single featured post-details pt-5 mt-5">
                <div className="container">
                    <div className="row">
                        {/* Main */}
                        <div className="col-12 col-lg-8 p-0">
                            <div className="row">
                                <div className="col-12 align-self-center">
                                    <h2 className="featured ml-0">{this.state.initData?.textTitle}</h2>

                                    {this.state.data.map((item, index) => (
                                        <>
                                            <h5>{item.header}</h5>
                                            <blockquote>
                                                {item?.detail.map((item) => (
                                                    <li>{item}</li>
                                                ))}
                                            </blockquote>

                                        </>
                                    )
                                    )}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default BlogSingle;