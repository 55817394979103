import React, { Component } from 'react';
import { getRewardItem } from '../../actions';

const initData = {
    pre_heading: "Exclusive Assets",
    heading: "ของรางวัล",
    btn_1: "View All",
    btn_2: "Load More",
    btnText: "แลกของรางวัล",
}

class ExploreOne extends Component {
    state = {
        data: [],
    }
    componentDidMount = async () => {
        const res = await getRewardItem()
        if (res.status === "success") {
            this.setState({ data: res.data })
        }
    }
    render() {
        const { data } = this.state;
        const { user } = this.props
        return (
            <section className="explore-area" style={{ marginTop: -70 }}>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            {/* Intro */}
                            <div className="intro d-flex justify-content-between align-items-end m-0">
                                <div className="intro-content">
                                    <h3 className="mt-3 mb-0">{initData.heading}</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row items">
                        {data.map((item, idx) => {
                            return (
                                <div key={`exo_${idx}`} className="col-12 col-sm-6 col-lg-3 item">
                                    <div className="card">
                                        <div className="image-over">
                                            <a href="#">
                                                <img className="card-img-top" src={item.picUrl.replace('.online','.co')} alt="" />
                                            </a>
                                        </div>
                                        {/* Card Caption */}
                                        <div className="card-caption col-12 p-0">
                                            {/* Card Body */}
                                            <div className="card-body">
                                                <a href="#">
                                                    <h5 className="mb-0">{item.name}</h5>
                                                </a>
                                                <div className="card-bottom d-flex justify-content-between">
                                                    <span style={{ fontWeight: 'bold', color: 'orange' }}>{`${item.point} แต้ม`}</span>
                                                </div>
                                                <a className="btn btn-bordered-white btn-smaller mt-3" href={user ? '/redeem?objectId=' + item.objectId + '&type=' + item.rewardType : '/login'}>
                                                    <i className="icon-handbag mr-2" />{initData.btnText}
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </section>
        );
    }
}

export default ExploreOne;