import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import Transaction from '../components/Transaction';

class App extends Component {
    render() {
        return (
            <div className="main">
                <Transaction />
            </div>
        );
    }
}

export default withRouter(App);