import React, { Component } from 'react';
import Swal from 'sweetalert2'
import moment from 'moment'
import { getCurrentUser } from '../../actions/api'
import BankIcon, { BANKS } from '../BankIcon'
import axios from 'axios';
const API_URL = process.env.REACT_APP_API_URL;
const initData = {
    pre_heading: "History",
    heading: "ประวัติการแลกของรางวัล",
}

class History extends Component {
    state = {
        currentUser: null,
        data: [],
        type:'item',
    }
    componentDidMount = async () => {
        this.simpleLoadData()
    }
    simpleLoadData = async () => {
        const {type} = this.state
        const user = await getCurrentUser()
        this.setState({ currentUser: user.toJSON() })
        const item = await (await axios(`${API_URL}/reward/redeem/list?userId=${user.id}&type=${type}`)).data;
        this.setState({ data: item.data })
    }
    render() {
        const { data } = this.state;
        return (
            <section className="author-area">
                <div className="container">
                    {/* Intro */}
                    <div className="intro text-center" style={{ marginBottom: 20 }}>
                        <span>{initData.pre_heading}</span>
                        <h4 className="mt-3 mb-0">{initData.heading}</h4>
                    </div>
                    <div className="row justify-content-center">
                        <div class="btn-group" role="group">
                            <button type="button" class="btn" onClick={()=>{
                                this.setState({type:'item'},()=>{ this.simpleLoadData()})
                            }}>Gadget</button>
                            <button type="button" class="btn" onClick={()=>{
                                this.setState({type:'cash'},()=>{ this.simpleLoadData()})
                            }}>เงินสด</button>
                        </div>
                    </div>
                    <div className="row justify-content-center">

                        <div className="col-12 col-md-8 col-lg-7">
                            {/* Item */}
                            {data.map((item) => (
                                <div className="item-form card no-hover py-3 my-4 px-3">
                                    <div className="row">
                                        <div className="col-12 col-sm-6">
                                            <h4 className='my-1 main-title'>{item.rewardName}</h4>
                                        </div>
                                        {item.rewardType === 'item' ?
                                            (<div className="col-sm-12">
                                                <div className='row'>
                                                    <p className='pr-3' style={{ marginTop: 8, color: 'white' }}>ที่อยู่ : {item.address}</p>
                                                </div>
                                            </div>) :
                                            (<div className="col-sm-12">
                                                <div className='row'>
                                                    <div className='pr-3' style={{ marginTop: 8, color: 'white' }}>บัญชี :</div>
                                                    <BankIcon isNameDiv={true} code={item.bankCode} style={{ marginTop: 8 }} />
                                                </div>
                                            </div>)
                                        }
                                        {item.rewardType === 'item' ?
                                            (<div className="col-12 col-sm-6">
                                                <div className='row'>
                                                    <p className='pr-3 text-muted' style={{ marginTop: 8 }}>เลขที่พัสดุ : {item.trackingCode}</p>
                                                </div>
                                            </div>) :
                                            (<div className="col-12 col-sm-6">
                                                <div className='row'>
                                                    <p className='pr-3' style={{ marginTop: 8, color: 'white' }}>เลขที่บัญชี : {item.bankAccountNumber}</p>
                                                </div>
                                            </div>)
                                        }
                                        <div className="col-12 col-lg-6 text-lg-right">
                                            <div className="font-weight-normal my-2">{`Point:`}<span style={{ color: 'orange' }}>{` ${item.redeemPoint}`}</span></div>
                                        </div>
                                        <div className="col-12 col-sm-6" style={{ marginTop: -8 }}>
                                            <div className='row'>
                                                <h5 className="font-weight-normal my-2">สถานะ : </h5>
                                                <div className='col-6'>
                                                    <h6 className='my-2' style={{ color: processStatus(item.status).color }}>{processStatus(item.status).name}</h6>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6 text-lg-right" style={{ marginTop: -8 }}>
                                            <h6 className="font-weight-normal my-2">{moment(item.createdAt).format('YYYY-MM-DD HH:mm:ss')}</h6>
                                        </div>
                                    </div>
                                </div>
                            ))}

                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default History;

const processStatus = (status) => {
    switch (status) {
        case 'completed':
            return { name: 'เรียบร้อย', color: '#389e0d' }
        case 'rejected':
            return { name: 'ไม่อนุมัติ', color: 'red' }
        case 'processing':
            return { name: 'กำลังดำเนินการ', color: '#00FFFF' }
        case 'waiting_for_approve':
            return { name: 'รอตรวจสอบ', color: '#3498DB' }
        case 'pending':
            return { name: 'ร้องขอ', color: '#faaf46' }
        case 'shipping':
            return { name: 'ระหว่างจัดส่ง', color: 'orange' }
        case 'canceled':
            return { name: 'ยกเลิก', color: 'red' }
        default: return { name: 'รอตรวจสอบ', color: '#9B59B6' }
    }
}