import React, { useState, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
//import logo from '../../image/poker-reward-logo-left.png'
import logo from '../../image/logo-header.png'
import { getCurrentUser } from '../../actions'

const Header = ({ user,setAuthenticated }) => {
    useEffect(()=>{
        setTimeout(()=>{
            window.postMessage('navResponsive','*')
        },3000)
    },[])
    return (
        <header id="header">
            <nav data-aos="zoom-out" data-aos-delay={400} className="navbar navbar-expand">
                <div className="container header">
                    <a className="navbar-brand" href="/">
                        <img className="navbar-brand-sticky" src={logo} alt="sticky brand-logo" />
                    </a>
                    <div className="ml-auto" />
                    <ul className="navbar-nav items" >
                        <li className="nav-item"><a href="/" className="nav-link" >Home</a></li>
                        <li className="nav-item"><a href="/rule" className="nav-link">กติกา</a></li>
                        <li className="nav-item">
                            <a href="/history" className="nav-link">ประวัติการแลก</a>
                        </li>
                        <li className="nav-item">
                            <a href="/transaction" className="nav-link">ประวัติแต้ม</a>
                        </li>
                        <li className="nav-item">
                            <a href="/tranferpoint" className="nav-link">โอนคะแนน</a>
                        </li>
                        <li className="nav-item">
                            {
                                isMobile ? null : (
                                    <div className="dropdown show">
                                        <a className="btn btn-secondary dropdown-toggle " href="/#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            {`ID: ${user.username}`}
                                        </a>
                                        <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                            <a className="dropdown-item">{`Point:`}
                                                <span style={{ marginLeft: 8, color: 'orange' }}>{`${user.rewardPoint}`}</span>
                                            </a>
                                            <div className="dropdown-divider" style={{ borderTop: '1px solid #ccc' }}></div>
                                            <a className="dropdown-item w-75" href="/security">ตั้งค่าความปลอดภัย</a>
                                            <a className="dropdown-item w-75" href="/changepassword">เปลี่ยนรหัสผ่าน</a>
                                            <a className="dropdown-item w-75" onClick={() => setAuthenticated()} href="#">Logout</a>
                                        </div>
                                    </div>
                                )
                            }
                        </li>
                    </ul>
                    <ul className="navbar-nav toggle">
                        <li className="nav-item">
                            <a href="#" className="nav-link" data-toggle="modal" data-target="#menu">
                                <i className="fas fa-bars toggle-icon m-0" />
                            </a>
                        </li>
                    </ul>
                    
                    <ul className="navbar-nav toggle">
                        <li className="nav-item">
                            {user ?
                                <div className="dropdown show">
                                    <a href="#" className="nav-link dropdown-toggle" data-toggle="dropdown">
                                        <i className="fas fa-user toggle-icon m-0" />
                                    </a>

                                    <ul className="dropdown-menu dropdown-menu-right" aria-labelledby="mobile_menu">
                                        <a className="dropdown-header">{`ID: ${user.username}`}</a>
                                        <a className="dropdown-item">{`Point:`}
                                            <span style={{ marginLeft: 8, color: 'orange' }}>{`${user.rewardPoint}`}</span>
                                        </a>
                                        <div className="dropdown-divider" style={{ borderTop: '1px solid #ccc' }}></div>
                                        <a className="dropdown-item" href="/security">ตั้งค่าความปลอดภัย</a>
                                        <a className="dropdown-item" href="/changepassword">เปลี่ยนรหัสผ่าน</a>
                                        <a className="dropdown-item" onClick={() => setAuthenticated()} href="#">Logout</a>
                                    </ul>

                                </div>
                                : null}
                        </li>
                    </ul>
                </div>
            </nav>
        </header>
    );
};

export default Header;