import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import ChangePasswordSection from '../components/ChangePassword/ChangePassword';
import Footer from '../components/Footer/Footer2';

class ChangePassword extends Component {
    render() {
        return (
            <div className="main">
                <ChangePasswordSection />
                <div style={{marginTop:300}}></div>
                <Footer />
            </div>
        );
    }
}

export default withRouter(ChangePassword);