import React, { Component } from 'react';
import axios from 'axios';
import moment from 'moment';
const BASE_URL = "https://my-json-server.typicode.com/themeland/netstorm-json-2/footer";

const FOOTER_CONTENT = `©${moment().format('YYYY')} Powered By`

class Footer extends Component {
    state = {
        data: {},
        socialData: [],
        widgetData_1: [],
        widgetData_2: []
    }
    componentDidMount() {
        axios.get(`${BASE_URL}`)
            .then(res => {
                this.setState({
                    data: res.data,
                    socialData: res.data.socialData,
                    widgetData_1: res.data.widgetData_1,
                    widgetData_2: res.data.widgetData_2
                })
                // console.log(this.state.data)
            })
            .catch(err => console.log(err))
    }
    render() {
        return (
            <footer className="footer-area">

                {/* Footer Bottom */}
                <div className="footer-bottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                {/* Copyright Area */}
                                <div style={{ textAlign: 'center', height: 60, padding: 16, width: '100%' ,}}>{FOOTER_CONTENT}<a style={{ marginLeft: 8 }} target="_blank" href="https://pokerth.online">{'pokerth.online'}</a></div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;