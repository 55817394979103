import React, { Component } from 'react';
import { getCurrentUser } from '../actions';
import Hero from '../components/Hero/Hero';
import Explore from '../components/Explore/ExploreOne';
import { withRouter } from 'react-router-dom';
import Footer from '../components/Footer/Footer2';
class ThemeOne extends Component {
    state = {
        user: null,
        loading: true,
    }
    componentDidMount = async () => {
        const user = await getCurrentUser()
        console.log('hero get current user :>> ', user);
        this.setState({ user, loading: false })
    }
    render() {
        const { user,loading } = this.state;
        return (
            !loading && <div className="main">
                <Hero user={user} />
                <Explore user={user} />
                <Footer/>
            </div>
        );
    }
}

export default withRouter(ThemeOne);