import React, { Component } from "react";
import Swal from "sweetalert2";
import LoginSection from "../components/Login/Login";
import OTPSection from "../components/Login/OTP";
import SMSSection from "../components/Login/sms";

import { login } from "../actions";
import { withRouter } from "react-router-dom";
import axios from "axios";
import Footer from "../components/Footer/Footer2";

const API_URL = process.env.REACT_APP_API_URL;

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: "",
            password: "",
            isLogin: false,
            tempToken: "",
            isUse2FA: false,
            otp: "",
            user: null,
            isLoginPass: false,
            otpToken: "",
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleInputChange(event) {
        event.preventDefault();
        const target = event.target;
        this.setState({
            [target.name]: target.value,
        });
    }
    resendOTP = async () => {
        const { username } = this.state;
        const otpres = await (await axios.post(`${API_URL}/user/otp/request`, { phone: username })).data;
        const { status, message, token } = otpres;
        if (status === "success") {
            Swal.fire({
                text: "Re-Send OTP",
                icon: "success",
            });
            this.setState({ otpToken: token });
        } else {
            Swal.fire({
                title: "Error!",
                text: message,
                icon: "error",
                confirmButtonText: "Ok",
            });
        }
    };

    handleSubmit = async (e) => {
        const { username, password } = this.state;
        console.log("username", username);
        console.log("password", password);
        const res = await login({ username, password });
        console.log("res", res);
        if (res.status === "success") {
            if (res.isUse2FA) {
                this.setState({ user: res.user, tempToken: res.token, isUse2FA: true });
            } else {
                const otpres = await (await axios.post(`${API_URL}/user/otp/request`, { phone: username })).data;
                const { status, message, token } = otpres;
                if (status === "success") {
                    this.setState({ user: res.user, tempToken: res.token, isLoginPass: true, otpToken: token });
                } else {
                    Swal.fire({
                        title: "Error!",
                        text: message,
                        icon: "error",
                        confirmButtonText: "Ok",
                    });
                }
            }
        } else {
            Swal.fire({
                title: "Error!",
                text: res.message,
                icon: "error",
                confirmButtonText: "Ok",
            });
        }
        e.preventDefault();
    };
    handleSubmit2FA = async (e) => {
        const { otp, tempToken, user } = this.state;
        const data = {
            userId: user.id,
            token: tempToken,
            _2fa: otp,
        };
        const res = await (await axios.post(`${API_URL}/user/2fa/verify`, data)).data;
        const { status, message } = res;
        if (status === "success") {
            localStorage.setItem("token", tempToken);
            window.location.href = "/";
        }
        Swal.fire({
            text: message,
            icon: status,
        });
    };
    handleSubmitOTP = async (e) => {
        const { otp, otpToken, tempToken } = this.state;
        const data = {
            token: otpToken,
            code: otp,
        };
        try {
            const res = await (await axios.post(`${API_URL}/user/otp/verify`, data)).data;
            const { status, message } = res;
            if (status === "success") {
                localStorage.setItem("token", tempToken);
                window.location.href = "/";
            }
            // Swal.fire({
            //     text: message,
            //     icon: status,
            // });
        } catch (error) {
            Swal.fire({
                text: error.message,
                icon: "error",
            });
        }
    };
    render() {
        const { isUse2FA, isLoginPass } = this.state;
        return (
            <div className="main">
                {isUse2FA ? (
                    <OTPSection handleInputChange={this.handleInputChange} state={this.state} handleSubmit={this.handleSubmit2FA} />
                ) : isLoginPass ? (
                    <SMSSection handleInputChange={this.handleInputChange} state={this.state} handleSubmit={this.handleSubmitOTP} resendOTP={this.resendOTP}/>
                ) : (
                    <LoginSection handleInputChange={this.handleInputChange} state={this.state} handleSubmit={this.handleSubmit} />
                )}
                <div style={{ marginTop: 350 }}></div>
                <Footer />
            </div>
        );
    }
}

export default withRouter(Login);
