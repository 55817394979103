import React, { Component } from "react";
import { getCurrentUser } from "../../actions/api";
import transferLogo from "../../image/transfer.png";
import receiveLogo from "../../image/receive.png";
import moment from "moment";
import axios from "axios";
import Swal from "sweetalert2";
import { v4 as uuidv4 } from "uuid";

const API_URL = process.env.REACT_APP_API_URL;
const icons = {
    transfer: transferLogo,
    receive: receiveLogo,
};
const initData = {
    pre_heading: "Tranfer Point",
    heading: "โอนคะแนน",
    textTable: "ประวัติการโอนคะแนน",
};

const data = [
    {
        point: "2000",
        recipient: "0800102030",
        sender: "0844485905",
        status: "completed",
        time: "2020-04-05 12:41:12",
        balance: "120",
    },
    {
        point: "2000",
        recipient: "0844485905",
        sender: "POKERTH",
        status: "completed",
        time: "2020-04-05 12:41:12",
        balance: "200",
    },
    {
        point: "2000",
        recipient: "0800102030",
        sender: "0844485905",
        status: "canceled",
        time: "2020-04-05 12:41:12",
        balance: "1440",
    },
];
class TranferPoint extends Component {
    state = {
        data: [],
        currentUser: null,
        loading: false,
        recipient: "",
        point: "",
        _2fa: "",
        otp: "",
        otpToken: "",
    };
    componentDidMount = async () => {
        this.simpleLoadData();
    };
    simpleLoadData = async () => {
        this.setState({ loading: false });
        const user = await getCurrentUser();
        await user.fetch();
        const item = await (await axios(`${API_URL}/reward/point/transfer/list?userId=${user.id}`)).data;
        console.log("item", item.data);
        this.setState({ currentUser: user.toJSON(), data: item.data, point: "", recipient: "" });
    };
    otpRequest = async () => {
        const { currentUser } = this.state;
        const { username } = currentUser;
        const res = await (await axios.post(`${API_URL}/user/otp/request`, { phone: username })).data;
        const { status, token, message } = res;
        if (status === "success") {
            Swal.fire({ title: "ส่ง OTP เรียบร้อย", text: `ตรวจสอบข้อความ OTP ที่เบอร์มือ ${username}`, icon: "success" });
            this.setState({ otpToken: token });
        } else {
            Swal.fire({
                title: "Error!",
                text: message,
                icon: "error",
                confirmButtonText: "Ok",
            });
        }
    };
    submit = async () => {
        this.setState({ loading: true });
        const { recipient, point, currentUser, _2fa, otp, otpToken } = this.state;
        const { rewardPoint } = currentUser;
        if (recipient === "" && point === "") {
            Swal.fire({
                title: "Error!",
                text: "กรุณากรอกข้อมูลให้ครบ",
                icon: "error",
                confirmButtonText: "Ok",
            });
            this.setState({ loading: false });
        } else if (rewardPoint < point) {
            Swal.fire({
                title: "Error!",
                text: "แต้มไม่พอ",
                icon: "error",
                confirmButtonText: "Ok",
            });
            this.setState({ loading: false });
        } else {
            const signature = uuidv4();
            const token = window.localStorage.getItem("token");
            const data = { recipient, point, userId: currentUser.objectId, signature, token, _2fa, otp, otpToken };
            const res = await axios.post(`${API_URL}/reward/point/transfer`, data);
            if (res.data.status === "success") {
                Swal.fire({ title: res.data.message, text: "รอแอดมินตรวจสอบการโอน", icon: res.data.status }).then(async (res) => {
                    this.simpleLoadData();
                });
            } else {
                Swal.fire({ title: res.data.message, icon: res.data.status });
                this.setState({ loading: false });
            }
        }
    };
    render() {
        const { currentUser, data } = this.state;
        const { isUse2FA } = currentUser || {};
        return (
            <section className="author-area">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-6 col-lg-6">
                            {/* Intro */}
                            <div className="text-center intro" style={{ marginBottom: 20 }}>
                                <span>{initData.pre_heading}</span>
                                <div style={{ fontSize: 22, color: "white", marginTop: 12 }}>{currentUser ? `ID: ${currentUser.username}` : ""}</div>
                                <div style={{ fontSize: 18, color: "white", marginTop: 8 }}>{`แต้มคงเหลือ : ${currentUser ? currentUser.rewardPoint : 0}`}</div>
                            </div>
                            {/* Item Form */}
                            <div className="item-form card no-hover py-5">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                value={this.state.recipient}
                                                className="form-control"
                                                name="recipient"
                                                onChange={(v) => {
                                                    this.setState({ recipient: v.target.value });
                                                }}
                                                placeholder="เลข User ผู้รับ (เบอร์โทร)"
                                                required="required"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                value={this.state.point}
                                                className="form-control"
                                                name="point"
                                                onChange={(v) => {
                                                    this.setState({ point: parseInt(v.target.value) });
                                                }}
                                                placeholder="จำนวน Point ที่ต้องการโอน"
                                                required="required"
                                            />
                                        </div>
                                    </div>
                                    {isUse2FA ? (
                                        <div className="col-12">
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    value={this.state._2fa}
                                                    className="form-control"
                                                    name="_2fa"
                                                    onChange={(v) => {
                                                        this.setState({ _2fa: v.target.value });
                                                    }}
                                                    placeholder="2FA Code"
                                                    required="required"
                                                />
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="col-12">
                                            <div className="form-group">
                                                <button
                                                    className="btn w-100 mt-2 mt-sm-2 mb-4"
                                                    disabled={this.state.loading}
                                                    onClick={() => {
                                                        this.otpRequest();
                                                    }}
                                                >
                                                    ขอ OTP
                                                </button>
                                                <input
                                                    type="text"
                                                    value={this.state.otp}
                                                    className="form-control"
                                                    name="otp"
                                                    onChange={(v) => {
                                                        this.setState({ otp: v.target.value });
                                                    }}
                                                    placeholder="SMS OTP Code"
                                                    required="required"
                                                />
                                            </div>
                                        </div>
                                    )}
                                    <div className="col-12">
                                        <button
                                            className="btn w-100 mt-2 mt-sm-2"
                                            disabled={this.state.loading}
                                            onClick={() => {
                                                this.submit();
                                            }}
                                        >
                                            ยืนยันโอนแต้ม
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <h4 className="mt-5 mb-0">{initData.textTable}</h4>
                            {data &&
                                data.map((item) => {
                                    const type = currentUser && currentUser.username === item.senderUsername ? "transfer" : "receive";
                                    return (
                                        <div className="item-form card no-hover my-3 py-3" style={{ padding: 0 }}>
                                            <div className="row pl-2 pr-2">
                                                <div className="col-6 col-sm-6">
                                                    <h5 className="font-weight-bold my-2">
                                                        <span>
                                                            <img
                                                                src={icons[type]}
                                                                style={{ width: 26, height: 45, marginRight: 8, marginBottom: -16, marginTop: -10 }}
                                                            />
                                                        </span>
                                                        {type === "transfer" ? "โอนแต้ม" : "รับแต้ม"}
                                                    </h5>
                                                </div>
                                                <div className="col-6" style={{ textAlign: "right" }}>
                                                    <div className="my-2" style={{ fontSize: 12 }}>
                                                        {moment(item.createdAt).format("YYYY-MM-DD HH:mm:ss")}
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <h5 className="my-1" style={{ fontWeight: "bold", color: "orange" }}>
                                                        {item.point}
                                                    </h5>
                                                </div>
                                                <div className="col-6">
                                                    <div className="my-1" style={{ color: "white", textAlign: "right" }}>
                                                        สถานะ :
                                                        <span style={{ marginLeft: 12, color: processStatus(item.status).color }}>
                                                            {processStatus(item.status).name}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <p className="pr-1 text-muted">
                                                        {type === "transfer" ? "ผู้รับ" : "ผู้ส่ง"}{" "}
                                                        <span className="text-white">{type === "transfer" ? item.recipientUsername : item.senderUsername}</span>
                                                    </p>
                                                </div>
                                                <div className="col-6" style={{ textAlign: "right" }}>
                                                    <div className="text-muted" style={{ textAlign: "right" }}>{`ยอดเดิม : ${
                                                        type === "transfer" ? item.senderPreviousPoint : item.recipientPreviousPoint
                                                    }`}</div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default TranferPoint;

const processStatus = (status) => {
    switch (status) {
        case "completed":
            return { name: "เรียบร้อย", color: "green" };
        case "rejected":
            return { name: "ไม่อนุมัติ", color: "red" };
        case "processing":
            return { name: "กำลังดำเนินการ", color: "#00FFFF" };
        case "waiting_for_approve":
            return { name: "รอตรวจสอบ", color: "#9B59B6" };
        case "pending":
            return { name: "ร้องขอ", color: "#3498DB" };
        case "shipping":
            return { name: "ระหว่างจัดส่ง", color: "orange" };
        case "canceled":
            return { name: "ยกเลิก", color: "red" };
        default:
            return { name: "รอตรวจสอบ", color: "#9B59B6" };
    }
};
