import React, { Component } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2'
import qs from 'query-string'
import BankIcon, { BANKS } from '../BankIcon'
import { getCurrentUser } from '../../actions/api'
import { v4 as uuidv4 } from 'uuid';

const API_URL = process.env.REACT_APP_API_URL;
const initData = {
    pre_heading: "Redeem Reward",
    heading: "แลกของรางวัล",
    content: "*กรุณาตรวจสอบข้อมูลในการจัดส่งให้ถูกต้อง"
}


class App extends Component {
    state = {
        name: '',
        bankCode: '006',
        bankAccountNumber: '',
        currentUser: null,
        loading: false,
        objectId: null,
        item: null,
    }
    componentDidMount = async () => {
        this.simpleLoadData()
    }
    simpleLoadData = async () => {
        const user = await getCurrentUser()
        await user.fetch({ useMasterKey: true })
        const currentUser = user.toJSON()
        console.log('c', currentUser);
        const { name, bankCode, bankAccountNumber } = currentUser
        this.setState({ currentUser, name, bankCode, bankAccountNumber })
        const search = qs.parse(window.location.search);
        const { objectId } = search
        const item = await (await axios(`${API_URL}/reward/item?objectId=${objectId}`)).data;
        this.setState({ objectId, item: item.data })
    }

    submit = async () => {
        this.setState({ loading: true })
        const { bankAccountNumber, name, bankCode, item, currentUser } = this.state;
        const { point, rewardType } = item || {}
        const { rewardPoint } = currentUser
        if (rewardType === 'cash' && bankAccountNumber === '' && bankCode === '' && name === '') {
            Swal.fire({
                title: 'Error!',
                text: 'กรุณากรอกข้อมูลให้ครบ',
                icon: 'error',
                confirmButtonText: 'Ok'
            })
            this.setState({ loading: false })
        } else if (rewardPoint < point) {
            Swal.fire({
                title: 'Error!',
                text: 'แต้มไม่พอ',
                icon: 'error',
                confirmButtonText: 'Ok'
            })
            this.setState({ loading: false })
        } else {
            const signature = uuidv4()
            const data = { bankAccountNumber, name, bankCode, rewardId: item.objectId, userId: currentUser.objectId, signature }
            const res = await axios.post(`${API_URL}/reward/redeem`, data);
            if (res.data.status === 'success') {
                Swal.fire({ title: res.data.message, text: 'รอแอดมินตรวจสอบของรางวัล', icon: res.data.status }).then(async res => {
                    window.location.href = '/history';
                });

            } else {
                Swal.fire({ title: res.data.message, icon: res.data.status });
                this.setState({ loading: false })
            }
        }
    }
    render() {
        const { item, bankCode, bankAccountNumber, name } = this.state;
        const { point } = item || {}
        return (
            <section className="author-area pt-5 mt-5">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-8 col-lg-7">
                            {/* Intro */}
                            <div className="intro text-center" style={{ marginBottom: 20 }}>
                                <span>{initData.pre_heading}</span>
                                <h3 className="mt-3 mb-0">{initData.heading}</h3>
                            </div>
                            {/* Item Form */}
                            <div className="item-form card no-hover">
                                <div className="row">
                                    <div className="col-12" style={{ marginBottom: 8, marginTop: -16 }}>
                                        <div style={{ color: 'white', fontSize: 20 }}>{`Item : ${item ? item.name : ''}`}</div>
                                        <div style={{ color: 'white' }}>{`แต้มที่ใช้แลก : `}
                                            <span style={{ color: 'orange' }}>{`${point || 0}`}</span>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group mt-4">
                                            <div style={{ color: 'white' }}>{`ธนาคาร : `}
                                                <span style={{ marginLeft: 8 }}><BankIcon code={bankCode} style={{ width: 30 ,marginRight:8}} />
                                                {`${BANKS.find(item=>item.code === bankCode).name_th}`}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group mt-1">
                                            <div style={{ color: 'white' }}>{`ชื่อบัญชี : ${name}`}</div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group mt-1">
                                            <div style={{ color: 'white' }}>{`เลขที่บัญชี : ${bankAccountNumber}`}</div>
                                        </div>
                                    </div>

                                    <div className="col-12">
                                        <button className="btn w-100 mt-3 mt-sm-4" disabled={this.state.loading} onClick={() => { this.submit() }}>ยืนยัน</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default App;