import React, { Component } from 'react';
import { getCurrentUser, logout } from '../../actions/api'
import axios from 'axios';
import Swal from 'sweetalert2'
import QRCode from "react-qr-code";
import './style.css'
const API_URL = process.env.REACT_APP_API_URL;

const initData = {
    pre_heading: "Security",
    heading: "ตั้งค่า 2FA",
    content: "*ในการเปิดใช้งานครั้งแรก คุณต้อง Scan QRCODE ด้วยแอพ Google Authenticator เก็บไว้ทันที ระบบจะแสดง QRCODE เพียงครั้งเดียวเท่านั้น"
}

class ChangePassword extends Component {
    state = {
        password: "",
        confirmPassword: "",
        currentUser: null,
        loading: false,
        _2fa: false,
        otp: ''
    }
    componentDidMount = async () => {
        const user = await getCurrentUser();
        this.setState({ currentUser: user.toJSON(), _2fa: user.get('isUse2FA') }, () => {

        });
    }
    submit = async () => {
        this.setState({ loading: true })
        const { currentUser, _2fa } = this.state;
        const token = window.localStorage.getItem('token')
        const data = {
            userId: currentUser.objectId,
            token
        }

        if (_2fa) {

            const res = await (await axios.post(`${API_URL}/user/2fa/enable`, data)).data
            const { status, otp, message } = res
            if (otp) {
                this.setState({ otp: `otpauth://totp/Reward%20Login%20Code?secret=${otp}&issuer=PokerTH` })
            }
            Swal.fire({ title: message, icon: status });
            this.setState({ loading: false })
        } else {
            const res = await (await axios.post(`${API_URL}/user/2fa/disable`, data)).data
            Swal.fire({ title: res.message, icon: res.status });
            this.setState({ loading: false })
        }
    }

    render() {
        const { currentUser, otp, _2fa } = this.state
        return (
            <section className="author-area">
                <div className="container">
                    {/* Intro */}
                    <div className="intro text-center" style={{ marginBottom: 0 }}>
                        <span>{initData.pre_heading}</span>
                        <h4 className="mt-3 mb-0">{initData.heading}</h4>
                        <p style={{ marginLeft: 20, marginRight: 20, color: 'red', fontSize: 18 }}>{initData.content}</p>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-6 col-lg-6">
                            {/* Item */}
                            {/* <div className="item-form card no-hover py-1 my-4">
                                <h6 className="font-weight-normal">รหัสผ่านเดิม</h6>
                                <input className="form-control mb-4" type="password"></input>
                            </div> */}
                            <div className="item-form card py-8 my-4">
                                <div className="form-group">
                                    <h6 className="font-weight-normal">{`เปิด / ปิด การใช้งาน 2FA`}</h6>
                                    <label class="switch" style={{ marginBottom: 24 }}>
                                        <input type="checkbox" checked={_2fa } onChange={(v) => {
                                            console.log('v :>> ', v.target.checked);
                                            this.setState({ _2fa: v.target.checked })
                                        }} />
                                        <span class="slider round"></span>
                                    </label>
                                    {
                                        otp !== '' && (
                                            <div style={{ height: "auto", margin: "20px auto", maxWidth: 150, width: "100%" }}>
                                                <QRCode
                                                    size={256}
                                                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                                    value={otp}
                                                    viewBox={`0 0 256 256`}
                                                />
                                            </div>
                                        )
                                    }
                                </div>
                                <button disabled={this.state.loading} onClick={() => { this.submit() }} className="btn btn-primary m-auto" style={{ marginBottom: 20 }}>Submit</button>

                            </div>

                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default ChangePassword;

const passwordValidation = (value) => {
    //var reg = new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{8,})");
    if (value.indexOf(' ') > -1) {
        return false
    }
    //var reg = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.{8,15}$)");
    var reg = new RegExp("^(?=.{8,15}$)");
    if (!reg.test(value)) {
        return false
    } else {
        return true
    }
}