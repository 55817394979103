import React, { Component } from 'react';
import BBL from '../../image/bank/bbl.svg'
import KBANK from '../../image/bank/kbank.svg'
import KTB from '../../image/bank/ktb.svg'
import TMB from '../../image/bank/tmb.svg'
import SCB from '../../image/bank/scb.svg'
import CIMBT from '../../image/bank/cimb.svg'
import GSB from '../../image/bank/gsb.svg'
import TBANK from '../../image/bank/tbank.svg'
import TISCO from '../../image/bank/tisco.svg'
import KK from '../../image/bank/kk.svg'
import LHBANK from '../../image/bank/lhb.svg'
import BAY from '../../image/bank/bay.svg'
import BAAC from '../../image/bank/baac.svg'
import TTB from '../../image/bank/ttb.svg'

export const BANKS = [
    { code: '002', name: 'BBL', name_th: 'กรุงเทพ', color: '#1e4598', src: BBL },
    { code: '004', name: 'KBANK', name_th: 'กสิกรไทย', color: '#138f2d', src: KBANK },
    { code: '006', name: 'KTB', name_th: 'กรุงไทย', color: '#1ba5e1', src: KTB },
    { code: '011', name: 'TMB', name_th: 'ทหารไทย', color: '#1279be', src: TMB },
    { code: '014', name: 'SCB', name_th: 'ไทยพาณิชย์', color: '#4e2e7f', src: SCB },
    { code: '022', name: 'CIMBT', name_th: 'ซีไอเอ็มบีไทย', color: '#7e2f36', src: CIMBT },
    { code: '025', name: 'BAY', name_th: 'กรุงศรีอยุธยา', color: '#fec43b', src: BAY },
    { code: '030', name: 'GSB', name_th: 'ออมสิน', color: '#eb198d', src: GSB },
    { code: '034', name: 'BAAC', name_th: 'ธกส.', color: '#4b9b1d', src: BAAC },
    { code: '065', name: 'TBANK', name_th: 'ธนชาต', color: '#fc4f1f', src: TBANK },
    { code: '067', name: 'TISCO', name_th: 'ทิสโก้', color: '#12549f', src: TISCO },
    { code: '069', name: 'KK', name_th: 'เกียรตินาคิน', color: '#199cc5', src: KK },
    { code: '073', name: 'LHBANK', name_th: 'แลนด์ แอนด์ เฮ้าส์', color: '#6d6e71', src: LHBANK },
    { code: '074', name: 'TTB', name_th: 'ทีเอ็มบีธนชาต', color: '#ffffff00', src: TTB },
];
class App extends Component {
    render() {
        const Bank = BANKS.find(item => item.code === this.props.code)
        return this.props.isNameDiv ? (<div style={{ ...this.props.style,paddingLeft: 8,paddingRight:8,borderRadius:4,width: 'auto', height: 22, backgroundColor: Bank.color, color: 'white' }}>{Bank.name_th}</div>) :
            (<img
                alt={this.props.alt}
                src={Bank.src}
                style={{ ...this.props.style, backgroundColor: Bank.color, borderRadius: '10%' }} />)


    }
}

export default App;