import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import qs from 'query-string'
import Redeem from '../components/Redeem';
import RedeemCash from '../components/Redeem/cash';

class App extends Component {
    state = {
        type:'item'
    }
    componentDidMount () {
        const search = qs.parse(window.location.search);
        const { type } = search
        console.log('type',type);
        this.setState({type})
    }
    render() {
        const { type } = this.state;
        return (
            <div className="main">
                {
                    type === 'cash' ? <RedeemCash /> : <Redeem />
                }
            </div>
        );
    }
}

export default withRouter(App);