import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import Security from '../components/Security/Security';
import Footer from '../components/Footer/Footer2';

class App extends Component {
    render() {
        return (
            <div className="main">
                <Security />
                <div style={{marginTop:250}}/>
                <Footer />
            </div>
        );
    }
}

export default withRouter(App);