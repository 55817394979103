import React, { Component } from 'react';
import Swal from 'sweetalert2'
import moment from 'moment'
import { getCurrentUser } from '../../actions/api'
import axios from 'axios';
const API_URL = process.env.REACT_APP_API_URL;
const initData = {
    pre_heading: "Point Transaction",
    heading: "ประวัติรายการแต้ม",
}

class History extends Component {
    state = {
        currentUser: null,
        data: []
    }
    componentDidMount = async () => {
        this.simpleLoadData()
    }
    simpleLoadData = async () => {
        const user = await getCurrentUser()
        this.setState({ currentUser: user.toJSON() })
        const item = await (await axios(`${API_URL}/reward/point/list?userId=${user.id}`)).data;
        console.log('item',item.data);
        this.setState({ data: item.data })
    }
    render() {
        const { data, currentUser } = this.state;
        return (
            <section className="author-area">
                <div className="container">
                    {/* Intro */}
                    <div className="intro text-center" style={{ marginBottom: 20 }}>
                        <span>{initData.pre_heading}</span>
                        <h4 className="mt-3 mb-0">{initData.heading}</h4>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-8 col-lg-7">
                            {/* Item */}
                            {data.map((item) => (
                                <div className="item-form card no-hover py-3 my-4 px-3">
                                    <div className="row">
                                        <div className="col-12">
                                            <h4 className='my-1 main-title' style={{ color: processType(item.type).color }}>{processType(item.type).name}</h4>
                                        </div>
                                        {
                                            item.type === 'transfer' ? item.senderId === currentUser.objectId ?
                                                (<div className="col-12" style={{marginTop:-8,marginBottom:4}}>
                                                    <div className='row' >
                                                        <p className='pr-3' style={{ marginTop: 8, color: 'white' }}>ผู้รับ : {item.recipientUsername}</p>
                                                    </div>
                                                </div>) :
                                                (<div className="col-12" style={{marginTop:-8,marginBottom:4}}>
                                                    <div className='row'>
                                                        <p className='pr-3' style={{ marginTop: 8, color: 'white' }}>รับจาก : {item.senderUsername}</p>
                                                    </div>
                                                </div>):null
                                        }

                                        <div className="col-12 col-sm-6" style={{ marginTop: -8 }}>
                                            <div className='row'>
                                                <h5 className="font-weight-normal my-2">แต้ม : </h5>
                                                <div className='col-6'>
                                                    <h5 className='my-2' style={{ color: 'orange' }}>{
                                                        item.type === 'rebate' || item.recipientId === currentUser.objectId ? `+ ${item.amount}`:`- ${item.amount}`
                                                    }</h5>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-12 col-sm-6 text-lg-right" style={{ marginTop: -8 }}>
                                            <h6 className="font-weight-normal my-2">{moment(item.createdAt).format('YYYY-MM-DD HH:mm:ss')}</h6>
                                        </div>
                                    </div>
                                </div>
                            ))}

                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default History;

const processType = (type) => {
    switch (type) {
        case 'redeem':
            return { name: 'แลกรางวัล', color: '#729137' }
        case 'transfer':
            return { name: 'โอนแต้ม', color: '#3498DB' }
        case 'rebate':
            return { name: 'รับแต้ม', color: '#faaf46' }
        default: return { name: 'รับแต้ม', color: '#faaf46' }
    }
}