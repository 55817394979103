import React, { Component } from 'react';
import { getCurrentUser, logout } from '../../actions/api'
import axios from 'axios';
import Swal from 'sweetalert2'
const API_URL = process.env.REACT_APP_API_URL;

const initData = {
    pre_heading: "Profile",
    heading: "เปลี่ยนรหัสผ่าน",
    content: "*การตั้งรหัสผ่านต้องไม่มีช่องว่าง มีมากกว่า 8 ตัวอักษร และไม่เกิน 15 ตัวอักษร"
}

class ChangePassword extends Component {
    state = {
        password: "",
        confirmPassword: "",
        currentUser: null,
        loading: false
    }
    componentDidMount = async () => {
        const user = await getCurrentUser();
        this.setState({ currentUser: user.toJSON() }, () => {

        });
    }
    submit = async () => {
        this.setState({ loading: true })
        const { currentUser, password, confirmPassword } = this.state;
        if (password === confirmPassword) {
            if (passwordValidation(password)) {
                const data = {
                    objectId: currentUser.objectId,
                    password
                }
                const res = await axios.post(`${API_URL}/user/reward/resetPassword`, data);
                if (res.data.status === 'success') {
                    Swal.fire({ title: res.data.message, text: 'กรุณา Log in ใหม่', icon: res.data.status }).then(async res => {
                        this.setState({ password: '', confirmPassword: '' });
                        window.location.href = '/';
                        await logout();
                    });

                } else {
                    Swal.fire({ title: res.data.message, icon: res.data.status });
                    this.setState({ loading: false })
                }
            } else {
                Swal.fire({
                    title: 'Error!',
                    text: 'กรุณากำหนด Password ให้ตรงตามเงื่อนไข',
                    icon: 'error',
                    confirmButtonText: 'Ok'
                })
                this.setState({ loading: false })
            }
        } else {
            Swal.fire({
                title: 'Error!',
                text: 'Password ไม่ตรงกัน',
                icon: 'error',
                confirmButtonText: 'Ok'
            })
            this.setState({ loading: false })
        }
    }

    render() {
        return (
            <section className="author-area">
                <div className="container">
                    {/* Intro */}
                    <div className="intro text-center" style={{ marginBottom: 0 }}>
                        <span>{initData.pre_heading}</span>
                        <h4 className="mt-3 mb-0">{initData.heading}</h4>
                        <p style={{ marginLeft: 20, marginRight: 20 }}>{initData.content}</p>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-6 col-lg-6">
                            {/* Item */}
                            {/* <div className="item-form card no-hover py-1 my-4">
                                <h6 className="font-weight-normal">รหัสผ่านเดิม</h6>
                                <input className="form-control mb-4" type="password"></input>
                            </div> */}
                            <div className="item-form card py-2 my-4">
                                <div className="form-group">
                                    <h6 className="font-weight-normal">รหัสผ่านใหม่</h6>
                                    <input className="form-control" type="password" onChange={(e) => {
                                        this.setState({ password: e.target.value })
                                    }}></input>
                                    <h6 className="font-weight-normal">ยืนยันรหัสผ่าน</h6>
                                    <input className="form-control mb-4" type="password" onChange={(e) => {
                                        this.setState({ confirmPassword: e.target.value })
                                    }}></input>
                                    <button disabled={this.state.loading} onClick={() => { this.submit() }} className="btn btn-primary m-auto" style={{ marginBottom: 20 }}>Submit</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default ChangePassword;

const passwordValidation = (value) => {
    //var reg = new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{8,})");
    if (value.indexOf(' ') > -1) {
        return false
    }
    //var reg = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.{8,15}$)");
    var reg = new RegExp("^(?=.{8,15}$)");
    if (!reg.test(value)) {
        return false
    } else {
        return true
    }
}